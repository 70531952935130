


































































































import Player from "@/components/Player.vue";
import { Product } from "@/types";
import { PropType } from "vue";
export default {
  name: "ProductCard",

  components: { Player },

  data: () => ({
    publicPath: process.env.VUE_APP_URL,
  }),

  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
};
