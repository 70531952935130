















import MovieListCard from "@/components/MovieListCard.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "MovieList",

  components: { MovieListCard },

  computed: {
    ...mapState(["movies"]),
  },

  beforeMount() {
    this.getMovies();
  },

  methods: {
    ...mapActions(["getMovies"]),
  },
};
