















































































































































































































































import BaseLoader from "@/components/BaseLoader.vue";
import Player from "@/components/Player.vue";
import ReleaseTracklistItem from "@/components/ReleaseTracklistItem.vue";
import { Link, Track } from "@/types";
import { mapActions, mapState } from "vuex";
export default {
  name: "ReleaseDetails",

  components: { BaseLoader, Player, ReleaseTracklistItem },

  data: () => ({
    loading: false,
    publicPath: process.env.VUE_APP_URL,
  }),

  watch: {
    "$route.params.slug": {
      handler: function () {
        this.loading = true;
        this.getRelease(this.$route.params.slug).then(() => {
          this.loading = false;
        });
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapState(["release"]),

    tracklist(): Track[] {
      return this.release.tracks?.filter((track: Track) => !track.bonus);
    },

    bonusTracklist(): Track[] {
      return this.release.tracks?.filter((track: Track) => track.bonus);
    },

    downloadUrl(): string | void {
      if (!this.release.links || !this.release.links.length) return;
      return (
        this.release.links.find(
          (link: Link) => link.platform.slug === "bandcamp"
        ).url + "?action=download"
      );
    },
  },

  methods: {
    ...mapActions(["getRelease"]),

    scrollTo(target: string) {
      return this.$vuetify.goTo(target);
    },
  },
};
