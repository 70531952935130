












import ProductCard from "@/components/ProductCard.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "Shop",

  components: { ProductCard },

  computed: {
    ...mapState(["products"]),
  },

  methods: {
    ...mapActions(["getProducts"]),
  },

  async beforeMount() {
    this.getProducts();
  },
};
