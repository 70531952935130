























import { Release } from "@/types";
import { PropType } from "vue";
export default {
  name: "ReleaseCard",

  data: () => ({
    publicPath: process.env.VUE_APP_URL,
  }),

  props: {
    release: {
      type: Object as PropType<Release>,
      required: true,
    },
    imgSize: {
      type: String,
      default: "500",
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
