






























import { Track } from "@/types";
import { PropType } from "vue";
export default {
  name: "ReleaseTracklistItem",

  props: {
    track: {
      type: Object as PropType<Track>,
      required: true,
    },

    releaseArtist: {
      type: String,
      required: true,
    },
  },
};
