









































































import Player from "@/components/Player.vue";
import { Link, Release } from "@/types";
import { PropType } from "vue";
export default {
  name: "LastRelease",

  components: { Player },

  data: () => ({
    publicPath: process.env.VUE_APP_URL,
  }),

  props: {
    release: {
      type: Object as PropType<Release>,
      required: true,
    },
  },

  computed: {
    releaseDownloadUrl(): string {
      return this.release?.links && this.release.links.length
        ? this.release.links.find(
            (link: Link) => link.platform.slug === "bandcamp"
          ).url + "?action=download"
        : "";
    },
  },
};
