














































































































































































import { mapState } from "vuex";
export default {
  name: "TheHeader",

  props: {
    shrink: {
      type: Boolean,
    },
  },

  data: () => ({
    drawer: false,
    navLinks: [
      { name: "/about", text: "about" },
      { name: "/artists", text: "artists" },
      { name: "/movies", text: "movies" },
      { name: "/proojects", text: "proojects", children: [] },
      { name: "/releases", text: "releases" },
      { name: "/shop", text: "shop" },
    ],
    socialLinks: [
      {
        name: "Bandcamp",
        url: "https://prootrecords.bandcamp.com/",
        icon: "mdi-cards-diamond",
      },
      {
        name: "Instagram",
        url: "https://www.instagram.com/proot_rec/",
        icon: "mdi-instagram",
      },
      {
        name: "Facebook",
        url: "https://www.facebook.com/Prootrecords",
        icon: "mdi-facebook",
      },
      {
        name: "YouTube",
        url: "https://www.youtube.com/channel/UCfsvySaVOwLZwt1tLna3vCQ/",
        icon: "mdi-youtube",
      },
    ],
  }),

  computed: {
    ...mapState(["proojects"]),
  },

  watch: {
    proojects() {
      if (this.proojects.length) {
        this.proojects.forEach((prooject) => [
          this.navLinks[3].children.push({
            name: "/" + prooject.slug,
            text: prooject.title,
          }),
        ]);
      }
    },
  },
};
