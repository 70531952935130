






























import { mapState } from "vuex";
export default {
  name: "TheSidebarReleases",

  data: () => ({
    publicPath: process.env.VUE_APP_URL,
  }),

  props: {
    scrolled: {
      type: Boolean,
    },
  },

  computed: {
    ...mapState(["releases"]),
  },
};
