




































import TheHeader from "@/components/TheHeader.vue";
import TheSidebar from "@/components/TheSidebar.vue";
import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "App",

  components: { TheHeader, TheSidebar },

  data: () => ({
    scrolled: false,
    scrollToTopBtn: false,
  }),

  beforeMount() {
    this.getProojects();
    this.getReleases();
  },

  watch: {
    scrolled() {
      this.scrollToTopBtn = this.scrolled;
    },
  },

  methods: {
    ...mapActions(["getProojects", "getReleases"]),

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.scrolled = top > 20;
    },
  },
});
