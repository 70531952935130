











































































import BaseLoader from "@/components/BaseLoader.vue";
import Player from "@/components/Player.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "ProojectDetails",

  components: { BaseLoader, Player },

  data: () => ({
    loading: false,
  }),

  watch: {
    "$route.params.slug": {
      handler: function () {
        this.loading = true;
        this.getProoject(this.$route.params.slug).then(() => {
          this.loading = false;
        });
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapState(["prooject"]),
  },

  methods: {
    ...mapActions(["getProoject"]),
  },
};
