



































import { Prooject } from "@/types";
import { PropType } from "vue";
export default {
  name: "ProojectHomeListItem",

  props: {
    prooject: {
      type: Object as PropType<Prooject>,
      required: true,
    },
  },
};
