












import { Movie } from "@/types";
import { PropType } from "vue";
export default {
  name: "MovieListCard",

  data: () => ({
    publicPath: process.env.VUE_APP_URL,
  }),

  props: {
    movie: {
      type: Object as PropType<Movie>,
      required: true,
    },
  },
};
