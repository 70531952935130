





























import LastRelease from "@/components/LastRelease.vue";
import HomeProojectListItem from "@/components/HomeProojectListItem.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "Home",

  components: { LastRelease, HomeProojectListItem },

  computed: {
    ...mapState(["release", "releases", "proojects"]),
  },

  watch: {
    releases() {
      if (this.releases.length) {
        this.getRelease(this.releases[0].slug);
      }
    },
  },

  beforeMount() {
    if (this.releases.length) {
      this.getRelease(this.releases[0].slug);
    }
  },

  methods: {
    ...mapActions(["getRelease"]),
  },
};
