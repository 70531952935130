















import ReleaseCard from "@/components/ReleaseCard.vue";
import { mapState } from "vuex";
export default {
  name: "ReleaseList",

  components: { ReleaseCard },

  computed: {
    ...mapState(["releases"]),
  },
};
