

























import { Artist } from "@/types";
import { PropType } from "vue";
export default {
  name: "ArtistListCard",

  data: () => ({
    publicPath: process.env.VUE_APP_URL,
  }),

  props: {
    artist: {
      type: Object as PropType<Artist>,
      required: true,
    },
  },
};
