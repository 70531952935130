var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"pa-2",class:{ shrink: _vm.shrink },attrs:{"fixed":"","height":"auto"}},[_c('v-app-bar-title',{staticClass:"mr-8"},[_c('router-link',{attrs:{"to":"/"}},[_c('h1',{staticClass:"proot--text"},[_vm._v("Proot")])]),_c('v-subheader',[_vm._v(" frivolous & original music ")])],1),_vm._l((_vm.navLinks),function(navLink){return _c('div',{key:navLink.name,staticClass:"d-none d-lg-block"},[(!navLink.children)?_c('v-list-item',{staticClass:"nav-item",attrs:{"to":navLink.name}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text text-uppercase",domProps:{"textContent":_vm._s(navLink.text)}})],1)],1):_c('v-list-item',{staticClass:"nav-item",attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-content',[_c('v-list-item-title',_vm._g(_vm._b({staticClass:"primary--text text-uppercase",domProps:{"textContent":_vm._s(navLink.text)}},'v-list-item-title',attrs,false),on))],1)]}}],null,true)},[_c('v-list',_vm._l((navLink.children),function(navLinkChild){return _c('v-list-item',{key:navLinkChild.name,attrs:{"to":navLink.name + navLinkChild.name}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(navLinkChild.text)}})],1)}),1)],1)],1)],1)}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-lg-none",attrs:{"to":"/releases","icon":"","small":_vm.shrink,"x-large":!_vm.shrink,"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-disc')}})],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Releases')}})]),_vm._l((_vm.socialLinks),function(socialLink){return _c('v-tooltip',{key:socialLink.name,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-md-flex",attrs:{"href":socialLink.url,"icon":"","small":_vm.shrink,"x-large":!_vm.shrink,"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(socialLink.icon)}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(socialLink.name)}})])}),_c('v-app-bar-nav-icon',{staticClass:"d-lg-none",attrs:{"color":"primary","small":_vm.shrink,"x-large":!_vm.shrink},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}})],2),_c('v-navigation-drawer',{directives:[{name:"show",rawName:"v-show",value:(_vm.drawer),expression:"drawer"}],staticClass:"text-center",attrs:{"fixed":"","bottom":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"d-flex pa-3"},[_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","x-large":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = false}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close')}})],1)],1),_c('div',{staticClass:"pa-3"},[_c('router-link',{attrs:{"to":"/"}},[_c('h1',{staticClass:"proot--text"},[_vm._v("Proot")])]),_c('v-subheader',{staticClass:"d-block mx-auto"},[_vm._v(" frivolous & original music ")])],1),_c('v-list',{staticClass:"my-5 d-flex flex-column",attrs:{"nav":"","dense":""}},_vm._l((_vm.navLinks),function(navLink){return _c('div',{key:navLink.name},[(!navLink.children)?_c('v-list-item',{staticClass:"nav-item",attrs:{"to":navLink.name}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text text-uppercase",staticStyle:{"font-size":"2rem","line-height":"2rem"},domProps:{"textContent":_vm._s(navLink.text)}})],1)],1):_vm._e()],1)}),0),_vm._l((_vm.socialLinks),function(socialLink){return _c('v-tooltip',{key:socialLink.name,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":socialLink.url,"icon":"","x-large":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(socialLink.icon)}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(socialLink.name)}})])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }