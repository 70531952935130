













































import ArtistDetails from "@/components/ArtistDetails.vue";
import ArtistListCard from "@/components/ArtistListCard.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "ArtistList",

  components: { ArtistDetails, ArtistListCard },

  data: () => ({
    loadingArtist: true,
  }),

  computed: {
    ...mapState(["artist", "artists"]),
  },

  watch: {
    "$route.params.slug": {
      handler: function () {
        if (this.$route.params.slug) {
          this.getArtist(this.$route.params.slug).then(() => {
            this.loadingArtist = false;
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["getArtist", "getArtists"]),
  },

  async beforeMount() {
    this.getArtists();
  },
};
